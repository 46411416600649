import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { mainApiConfig } from "./mainApiConfig";
import ApolloClient from "apollo-client";
import { CachePersistor } from 'apollo-cache-persist';

const cache = new InMemoryCache();

// Load cache from the persisited storage.
export const apolloCachePersistor = new CachePersistor({
    cache,
    storage: (window.localStorage as any),
});
apolloCachePersistor.persist();

const link = new HttpLink({
    uri: mainApiConfig.endpoint,
    credentials: 'same-origin',
});

export const apolloClient = new ApolloClient({
    cache,
    link
});